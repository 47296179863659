@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Courgette&display=swap');

@import url('../foundation-icons/foundation-icons.css');

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'main'; //magnific-popup

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


@font-face {
	font-family: 'planet_estyleregular';
	src: url('../fonts/plane___-webfont.eot');
	src: url('../fonts/plane___-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/plane___-webfont.woff') format('woff'),
	url('../fonts/plane___-webfont.ttf') format('truetype'),
	url('../fonts/plane___-webfont.svg#planet_estyleregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin arrow($radius, $bgcolor, $fontcolor) {
	display: inline-block;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: rgba($bgcolor ,1);
	height: rem-calc($radius*2);
	width: rem-calc($radius*2);
	color: $fontcolor;
	font-size: rem-calc(($radius*1.5));
	font-family: $body-font-family-normal;
	text-align: center;
	line-height: rem-calc((($radius*2)-2));
}
.nixhier
{
	display: none !important;
}

.palmedia {
	font-family: "planet_estyleregular", serif;

	font-size: 110%;
	color: $white;
	&:hover {
		color: $medium-gray;
	}
}


body {
  background: $white;
  // background-image: url('../img/bg.jpg'); /* fallback */
  background: linear-gradient(to bottom, transparent 70%, white), url('../img/bg-full.jpg') no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  @include breakpoint(medium) {
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
  }
}

.off-canvas {
  .mini-emblem {
	display: block;
	text-align: center;
	padding: 10px 0 0 0;
	&::after {
	  content: "";
	  background: rgba($primary-color, 1);
	  top: 0;
	  left: 0;
	  height: 30px;
	  right: 0;
	  position: absolute;
	  z-index: -1;
	}
	img, svg {
	  //z-index: 14;
	  width: rem-calc(50);
	  @include breakpoint(large) {
		//width: rem-calc(350);
	  }
	}
  }
}

.small-header {
	position: relative;
	border: 1px solid transparent;
	.logo img {
		position: absolute;
		top: rem-calc(20);
		right: rem-calc(10);
		width: rem-calc(200);
		@include breakpoint(medium) {
			width: rem-calc(250);
			right: auto;
			left: rem-calc(180);
			top: rem-calc(30);
		}
	}
	.emblem img {
		z-index: 12;
		position: absolute;
		left: rem-calc(25);
		top: rem-calc(5);
		width: rem-calc(80);
		@include breakpoint(medium) {
			width: rem-calc(125);
		}
	}
	.small-top-bar {
		background: rgba($white, .3);
		height: rem-calc(43);
		margin: rem-calc(60) 0 rem-calc(10);
		padding: rem-calc(0) rem-calc(25);
		padding-left: rem-calc(200);
		font-size: rem-calc(15);
		-webkit-filter: drop-shadow( 0px 0px 2px rgba($primary-color,.4) );
		filter: drop-shadow( 0px 0px 2px rgba($primary-color,.4) );
		@include breakpoint(medium) {
			margin: rem-calc(80) rem-calc(-15) rem-calc(80);
		}
		.hamburger-menu {
			margin-top: rem-calc(10);
			@include hamburger($primary-color, $black, 30px, 26px, 5px, 3);
		}
	}
}

.header {
	position: relative;
	top: rem-calc(45);
	margin-bottom: rem-calc(200);
	//height: rem-calc(150);
	@include breakpoint(large) {
		//height: rem-calc(250);
	}
	.logo img {
		 position: relative;
		left: rem-calc(210);
		@include breakpoint(large) {
			//width: rem-calc();
		}
	}
	.emblem {
	  img, svg {
		z-index: 999;
		position: absolute;
		left: rem-calc(60);
		top: rem-calc(-3);
		width: rem-calc(125);
		@include breakpoint(large) {
		  //width: rem-calc(350);
		}
	  }
	}
	.top-bar {
		z-index: 100;
		margin: rem-calc(5) rem-calc(-15);
		padding: 0;
		padding-left: rem-calc(200);
		font-size: rem-calc(15);
	  	border: 1px solid rgba($primary-color,.2);
		.menu {
			a {
				font-family: $body-font-family-normal;
				margin: rem-calc(5) rem-calc(2);
				color: $primary-color;
			}
			.submenu {
				padding: rem-calc(5) rem-calc(0);
			  	border: 1px solid rgba($primary-color,.2);
				z-index: 99;
				a {
					margin: rem-calc(0) rem-calc(2);
				  	&:hover {
					  background: rgba($light-gray,.5);
					}
				}
			}
		}
	}
	.tools-menu {
		position: relative;
		.search {
			position: absolute;
			right: 35px;
			top: 15px;
			form {
				margin:0;
				padding:0;
			}
			width: 300px;
			display: inline-block;
			.search-field {
				display: inline-block;
				width: 0;
				height: 32px;
				padding: 0;
				border-radius: 50px;
				border: none;
				transition: all 0.5s ease;
				$searchfield-radius: 0;
				border-radius: $searchfield-radius;
				-webkit-border-radius: $searchfield-radius;
				-moz-border-radius: $searchfield-radius;
			}

			.expand-search {
				width: 75%;
				height: 32px;
				max-width: calc(90%);
				padding: 0 1rem;
				transition: all 0.5s ease;
			}

			.search-button {
				position: absolute;
				right:0;
				top: 0;
				height: 32px;
				width: 32px;
				border: 0;
				background-color: transparent;
				$searchfield-radius: 0;
				border-radius: $searchfield-radius;
				-webkit-border-radius: $searchfield-radius;
				-moz-border-radius: $searchfield-radius;
				.fi-magnifying-glass:before {
					font-size: 30px;
					color: $white;
					-webkit-filter: drop-shadow( 0px 0px 2px rgba($primary-color,.4) );
					filter: drop-shadow( 0px 0px 2px rgba($primary-color,.4) );
				}
				&:hover {
					.fi-magnifying-glass:before {
						color: $primary-color;
					}
				}
			}
		}
		.social-button {
			position: absolute;
			display: inline-block;
			right: 0;
			top:0;
			height: 32px;
			width: 32px;
			img {
				height: 32px;
				width: 32px;
			}
		}
	}
}

.main-content {
	//-webkit-filter: drop-shadow( 0px 0px 1px rgba($primary-color,.4) );
	//filter: drop-shadow( 0px 0px 1px rgba($primary-color,.4) );
	.cell {
		background: $white;
	}
	//.large-auto {
	//	border-right: 1px solid $light-gray;
	//}
	.published_date {
		color: $dark-gray;
	}
	.news-container {
		margin-bottom: rem-calc(10);
		.news-item {
			@include flex-grid-row(nest, $global-width, 12);
			@include flex-align(null, middle);
			justify-content: center;
			.news-text {
				@include flex-grid-column(10);
				font-size: rem-calc(13);
				color: $black;
				.news-title {
					font-size: rem-calc(13);
					font-weight: 400;
					font-family: $body-font-family-normal;
					color: $black;
				}
			}
			.news-arrow {
				@include flex-grid-column(2);
				font-size: rem-calc(28);
				color: $primary-color;
				font-weight: bold;
			}
			//@include breakpoint(medium) {
			//	@include flex-grid-column(10);
			//}
		}
	}
	.agenda-container {
		margin-bottom: rem-calc(15);
		.agenda-item {
			@include flex-grid-row(nest, $global-width, 12);
			@include flex-align(null, middle);
			justify-content: center;
			.agenda-text {
				@include flex-grid-column(10);
				font-size: rem-calc(13);
				color: $black;
				.agenda-title {
					font-size: rem-calc(13);
					font-weight: 400;
					font-family: $body-font-family-normal;
					color: $black;
				}
			}
			.agenda-arrow {
				@include flex-grid-column(2);
				font-size: rem-calc(28);
				color: $primary-color;
				font-weight: bold;
			}
			//@include breakpoint(medium) {
			//	@include flex-grid-column(10);
			//}
		}
	}
	.hotlink-spacer {
		height: rem-calc(0);
		@include breakpoint(large) {
			height: rem-calc(35);
		}
	}
	.hotlink-item {
		margin-bottom: rem-calc(20);
		@include flex-grid-row(nest, $global-width, 12);
		@include flex-align(null, middle);
		justify-content: center;
		.hotlink-button-container {
			@include flex-grid-column(9);
			.hotlink-button {
				font-size: rem-calc(17);
				small {
					font-size: rem-calc(13);
				}
			}
		}
		.hotlink-arrow-container {
			@include flex-grid-column(3);
			text-align: right;
			@include breakpoint(medium) {
				text-align: left;
			}
			.hotlink-arrow {
				@include arrow(22, $secondary-color, $white);
				margin-right: rem-calc(16);
				@include breakpoint(medium) {
					margin-right: rem-calc(8);
				}
			}
		}
	}
	.highlight-item-wrapper {
		position: relative;
		a {
			display: inline-block;
			letter-spacing: normal;
			line-height: initial;
			margin: 0;
			padding: 0;
			position: relative;
			h2 {
				position: absolute;
				background: linear-gradient(to bottom, transparent 0%, $primary-color);
				font-family: $body-font-family-elegant;
				font-size: rem-calc(25);
				text-align: right;
				color: $white;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 5px 75px 16px 3px;
				@include breakpoint(medium) {
					font-size: rem-calc(20);
					padding-bottom: 19px;
				}
				margin: 0;
				z-index: 10;
			}
			.highlight-item-arrow {
				@include arrow(22, $secondary-color, $white);
				position: absolute;
				right: 5px;
				bottom: 10px;
				z-index: 11;
				margin: 0 rem-calc(8);
			}
			img {
				float: left;
				position: relative;
				overflow: hidden;
				width: 100%;
			}
		}
	}
	.highlight-left-item-wrapper,
	.highlight-links-item-wrapper {
		position: relative;
		.hightlight-item-image-offset {
			@include breakpoint(medium) {
				//
			}
		}
		.hightlight-item-content {
			@include breakpoint(medium) {
				position: absolute;
				display: block;
				width: inherit;
				right: 20px;
				top: 30px;
				bottom: auto;
			}
			@include breakpoint(large) {
				top: auto;
				bottom: 50px;
			}
			background: rgba($primary-color, .9);
			color: $white;
			letter-spacing: normal;
			line-height: initial;
			padding: rem-calc(35);
			z-index: 10;
			h2 {
				font-family: $body-font-family-elegant;
				font-size: rem-calc(20);
				color: $white;
				@include breakpoint(medium) {
					font-size: rem-calc(20);
					padding-bottom: 14px;
				}
				margin: 0;
			}
			p {

			}
			.highlight-item-arrow {
				@include arrow(22, $secondary-color, $white);
				position: absolute;
				right: 15px;
				bottom: 20px;
				@include breakpoint(medium) {
					right: 5px;
					bottom: 10px;
				}
				z-index: 11;
				margin: 0 rem-calc(8);
			}
		}
		img {
			@include breakpoint(medium) {
				float: left;
				position: relative;
			}
			overflow: hidden;
			width: 100%;
		}
	}
	.highlight-right-item-wrapper,
	.highlight-rechts-item-wrapper {
		position: relative;
		.hightlight-item-image-offset {
			@include breakpoint(medium) {
				@include xy-cell-offset(4);
			}
		}
		.hightlight-item-content {
			@include breakpoint(medium) {
				display: block;
				width: inherit;
				position: absolute;
				left: 20px;
				top: 30px;
				bottom: auto;
			}
			@include breakpoint(large) {
				top: auto;
				bottom: 50px;
			}
			background: rgba($primary-color, .9);
			color: $white;
			letter-spacing: normal;
			line-height: initial;
			padding: rem-calc(35);
			z-index: 10;
			h2 {
				font-family: $body-font-family-elegant;
				font-size: rem-calc(20);
				color: $white;
				@include breakpoint(medium) {
					font-size: rem-calc(20);
					padding-bottom: 14px;
				}
				margin: 0;
			}
			p {

			}
			.highlight-item-arrow {
				@include arrow(22, $secondary-color, $white);
				position: absolute;
				right: 15px;
				bottom: 20px;
				@include breakpoint(medium) {
					right: 5px;
					bottom: 10px;
				}
				z-index: 11;
				margin: 0 rem-calc(8);
			}
		}
		img {
			@include breakpoint(medium) {
				float: left;
				position: relative;
			}
			overflow: hidden;
			width: 100%;
		}
	}
	.sidebar {
		h2 {
			a {
				color: $primary-color;
				&:hover {
					color: $body-font-color;
				}
			}
		}
		h3 {
			a {
				font-size: rem-calc(15);
				color: $black;
			}
		}
	}

	.sidebar-item {
		border-bottom: 1px solid $light-gray;
		margin: auto;
		line-height: rem-calc(35);
		position: relative;
		a {
			font-weight: 400;
			color: $primary-color;

		}
		&:last-child {
			border-bottom: 0;
		}
	}


	.calendar-month {
		display: inline-block;
		color: $primary-color;
		padding: 0 0 rem-calc(10) 0;
		font-size: 120%
	}

	.calendar-icon-round {
		$calendar-radius: rem-calc(25);
		$calendar-line-height: rem-calc(50);
		display: block;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background-color: rgba($primary-color ,0.65);
		border: 1px solid $primary-color;
		height: $calendar-radius*2;
		width: $calendar-radius*2;
		color: $white;
		font-size: rem-calc(20);
		text-align: center;
		line-height: $calendar-line-height;
		margin-right: rem-calc(8);
	}

	.calender-text {
		margin: rem-calc(13) auto rem-calc(5) auto;
	}

	.news-item {
		border-bottom: 1px solid $light-gray;
		margin: auto auto rem-calc(10);
		position: relative;
		p {
			a {
				font-weight: 400;
				color: $primary-color;

			}
			margin: auto auto rem-calc(10);
		}
		&:last-child {
			border-bottom: 0;
		}
	}

	.bulletin-item {
		border-bottom: 1px solid $light-gray;
		margin: auto auto rem-calc(10);
		position: relative;
		p {
			a {
				font-weight: 400;
				color: $primary-color;

			}
			margin: auto auto rem-calc(10);
		}
		&:last-child {
			border-bottom: 0;
		}
	}

	// NEW calendar
	.calendar-item {
		border-bottom: 1px solid $medium-gray;
		margin: auto auto rem-calc(10);
		position: relative;
		&:after {
			content: '>';
			font-weight: bold;
			color: $primary-color;
			position: absolute;
			right: 0;
			top: 20%;
		}
		&:last-child {
			border-bottom: 0;
		}
		a {
			display: block;
			color: $black;
			font-size: 110%;
			margin: auto auto rem-calc(10);
			.date {
				color: $primary-color;
				font-size: 90%;
				display: block;
			}
		}
	}


	.photos-more-link {
		font-size: rem-calc(19);
		a {
			color: $primary-color;
		}
	}

	.published_date {
		text-transform: lowercase;
		color: $dark-gray;
		font-size: rem-calc(13);
		margin-bottom: rem-calc(10);
		&:before {
			margin-right: 5px;
		}
	}
	.share {
		color: $dark-gray;
		a {
			color: $dark-gray;
			&:hover {
				color: $primary-color;
			}
			span {
				margin-left: 5px;
				&:before {
					margin-right: 5px;
				}
			}
		}
	}

	.calendar-item-cell
	{
		background-color: rgba($primary-color, 1);
		a {
			display: block;
		  	color: $white;
		}
	}
}



.footer {
	background-color: $primary-color;
	position: relative;
	color: $white;
	font-size: rem-calc(14);
	padding-top: 20px;
	@include breakpoint(medium) {
		padding-top: 0;
	}
	.margin-left {
		@include breakpoint(medium) {
			margin-left: rem-calc(160);
		}
	}
	&:before {
		content: url('../img/wapen-van-geesteren.svg');
		position: absolute;
		width: 50px;
		left: 26px;
		top: -49px;
		@include breakpoint(small) {
			transform: scale(.8);
			top: -46px;
		}
	}
	a {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $light-gray;
			text-decoration: underline;
		}
	}
	h2 {
		@include breakpoint(small) {
			display: inline-block;
			margin-right: 20px;
		}
		@include breakpoint(medium) {
			margin-top: rem-calc(30);
			display: block;
		}
		font-family: $body-font-family-elegant;
		font-size: 24px;
		color: $white;
	}
	.social-button {
		font-family: "foundation-icons";
		color: $white;
		font-size: 40px;
		margin-right: 20px;
	}

}
